var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CSidebar",
    {
      staticClass: "c-sidebar-light",
      attrs: { minimize: _vm.minimize, unfoldable: "", show: _vm.show },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("CSidebarBrand", {
        attrs: {
          imgFull: {
            width: 148,
            height: 16,
            alt: "Logo",
            src: "img/logo_ocw.svg"
          },
          imgMinimized: {
            width: 148,
            height: 150,
            alt: "Logo",
            src: "img/brand/coreui-signet-white.svg"
          },
          wrappedInLink: { href: "/" }
        }
      }),
      _c("CRenderFunction", { attrs: { flat: "", contentToRender: _vm.nav } }),
      _c(
        "button",
        {
          staticClass: "btn btn-info",
          staticStyle: { margin: "2rem 1rem" },
          attrs: { type: "button" },
          on: { click: _vm.signOut }
        },
        [_vm._v("ログアウト")]
      ),
      _c("CSidebarMinimizer", {
        staticClass: "c-d-md-down-none",
        nativeOn: {
          click: function($event) {
            _vm.minimize = !_vm.minimize
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }