var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CFooter", [
    _c("div", [
      _c("a", { attrs: { href: "https://coreui.io", target: "_blank" } }, [
        _vm._v("CoreUI")
      ]),
      _c("span", { staticClass: "ml-1" }, [_vm._v("© 2019 creativeLabs.")])
    ]),
    _c("div", { staticClass: "ml-auto" }, [
      _c("span", { staticClass: "mr-1", attrs: { target: "_blank" } }, [
        _vm._v("オープンクーポン株式会社")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }