import {UserRole} from '@/common/const';

export default [{
  _name: 'CSidebarNav',
  _children: [
    /*{
      _name: 'CSidebarNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'cil-speedometer',
      badge: {
        color: 'primary',
        text: 'NEW'
      }
    },*/
    /* {
      _name: 'CSidebarNavTitle',
      _children: ['account']
    }, */
    {
      _name: 'CSidebarNavDropdown',
      name: 'アカウント管理',
      route: '/accounts',
      icon: 'cil-address-book',
      user_roles: [
        UserRole.ADMIN,
        UserRole.MAKERS,
        UserRole.DISTRIBUTIONS,
        UserRole.WHOLESALE,
        UserRole.REGISTER,
      ],
      items: [
        {
          name: 'アカウント一覧',
          to: '/accounts/tables/makers',
          icon: 'cil-list',
          user_roles: [
            UserRole.ADMIN,
          ],
        },
        {
          name: 'アカウント登録',
          to: '/accounts/new',
          icon: 'cil-contact',
          user_roles: [
            UserRole.ADMIN,
          ],
        },
        {
          name: 'アカウント編集',
          to: '/accounts/me',
          icon: 'cil-contact',
          user_roles: [
            UserRole.MAKERS,
            UserRole.DISTRIBUTIONS,
            UserRole.WHOLESALE,
            UserRole.REGISTER,
          ],
          /* badge: {
            color: 'danger',
            text: 'PRO'
          } */
        }
      ]
    },
    /* {
      _name: 'CSidebarNavTitle',
      _children: ['coupon']
    }, */
    {
      _name: 'CSidebarNavDropdown',
      name: 'クーポン管理',
      route: '/coupons',
      icon: 'cil-notes',
      user_roles: [
        UserRole.ADMIN,
        UserRole.MAKERS,
        UserRole.DISTRIBUTIONS,
        UserRole.WHOLESALE,
      ],
      items: [
        {
          name: 'クーポン一覧',
          to: '/coupons',
          icon: 'cil-grid',
          user_roles: [
            UserRole.ADMIN,
            UserRole.MAKERS,
            UserRole.DISTRIBUTIONS,
            UserRole.WHOLESALE,
          ],
        },
        {
          name: 'クーポン登録',
          to: '/coupons/new',
          icon: 'cil-notes',
          user_roles: [
            UserRole.ADMIN,
            UserRole.MAKERS,
            UserRole.DISTRIBUTIONS,
            UserRole.WHOLESALE,
          ],
        },
        {
          name: 'クーポン取込',
          to: '/coupons/import',
          icon: 'cil-notes',
          user_roles: [
            UserRole.ADMIN
          ],
        },
        {
          name: 'クーポン店舗取込',
          to: '/coupons/import-store',
          icon: 'cil-notes',
          user_roles: [
            UserRole.ADMIN,
            UserRole.DISTRIBUTIONS,
          ],
        }
      ]
    },
    /* {
      _name: 'CSidebarNavTitle',
      _children: ['data']
    }, */
    {
      _name: 'CSidebarNavDropdown',
      name: '実績管理',
      route: '/report',
      icon: 'cil-bell',
      user_roles: [
        UserRole.ADMIN,
        UserRole.MAKERS,
        UserRole.DISTRIBUTIONS,
        UserRole.WHOLESALE,
        UserRole.REGISTER,
      ],
      items: [
        {
          name: '実績レポート',
          to: '/report/report',
          icon: 'cil-file',
          user_roles: [
            UserRole.ADMIN,
            UserRole.MAKERS,
            UserRole.DISTRIBUTIONS,
            UserRole.WHOLESALE,
            UserRole.REGISTER,
          ],
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: '確定処理',
      route: '/closing',
      icon: 'cil-check',
      user_roles: [
        UserRole.ADMIN,
        UserRole.MAKERS,
        UserRole.DISTRIBUTIONS,
        UserRole.WHOLESALE,
        UserRole.REGISTER,
      ],
      items: [
        {
          name: 'OC確定処理',
          to: '/closing/oc-closing',
          icon: 'cil-check',
          user_roles: [
            UserRole.ADMIN,
          ],
        },
        {
          name: '月次確定処理',
          to: '/closing/user-closing',
          icon: 'cil-calendar',
          user_roles: [
            UserRole.MAKERS,
            UserRole.DISTRIBUTIONS,
            UserRole.WHOLESALE,
            UserRole.REGISTER,
          ],
        },
      ]
    },
    // {
    //   _name: 'CSidebarNavDropdown',
    //   name: '夜間バッチ',
    //   route: '/apibatch',
    //   icon: 'cil-bell',
    //   user_roles: [
    //     UserRole.DISTRIBUTIONS,
    //   ],
    //   items: [
    //     {
    //       name: 'バッチ処理状況',
    //       to: '/apibatch/apibatch',
    //       icon: 'cil-bell',
    //       user_roles: [
    //         UserRole.DISTRIBUTIONS,
    //       ],
    //     },
    //   ]
    // },
    {
      _name: 'CSidebarNavDropdown',
      name: 'メッセージ',
      route: '/message',
      icon: 'cil-bell',
      user_roles: [
        UserRole.DISTRIBUTIONS,
      ],
      items: [
        {
          name: '店舗メッセージ送信',
          to: '/message/storemessage',
          icon: 'cil-bell',
          user_roles: [
            UserRole.DISTRIBUTIONS,
          ],
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'お知らせ',
      route: '/info',
      icon: 'cil-info',
      user_roles: [
        UserRole.ADMIN,
      ],
      items: [
        {
          name: 'お知らせ登録',
          to: '/info/info',
          icon: 'cil-speech',
          user_roles: [
            UserRole.ADMIN,
          ],
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'PUSH通知',
      route: '/notices',
      icon: 'cil-bell',
      user_roles: [
        UserRole.DISTRIBUTIONS,
        UserRole.MAKERS,
      ],
      items: [
        {
          name: 'ターゲットPUSH通知',
          to: '/notices/targetsegment/new',
          icon: 'cil-grid',
          user_roles: [
            UserRole.DISTRIBUTIONS,
            UserRole.MAKERS,
          ]
        },
      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: '開発者',
      route: '/develop',
      icon: 'cil-input-power',
      user_roles: [
        UserRole.ADMIN,
      ],
      items: [
        {
          name: 'APIコマンド作成',
          to: '/develop/command',
          icon: 'cil-code',
          user_roles: [
            UserRole.ADMIN,
          ],
        },
        {
          name: '統計資料',
          to: '/develop/statistics',
          icon: 'cil-grid',
          user_roles: [
            UserRole.ADMIN,
          ],
        },
      ]
    },
  ]
}]