var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-app" },
    [
      _c("TheSidebar"),
      _c(
        "div",
        { staticClass: "c-wrapper" },
        [
          _c("TheHeader"),
          _c("div", { staticClass: "c-body" }, [
            _c(
              "main",
              { staticClass: "c-main" },
              [
                _c(
                  "CContainer",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade" } },
                      [_c("router-view")],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ]
        },
        [_vm._m(0)]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "gray" } }, [
      _c("div", { staticClass: "sk-wave", attrs: { id: "loading-sp" } }, [
        _c("div", { staticClass: "sk-wave-rect" }),
        _c("div", { staticClass: "sk-wave-rect" }),
        _c("div", { staticClass: "sk-wave-rect" }),
        _c("div", { staticClass: "sk-wave-rect" }),
        _c("div", { staticClass: "sk-wave-rect" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }