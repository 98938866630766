<template>
  <CSidebar 
    :minimize="minimize"
    unfoldable
    :show.sync="show"
    class="c-sidebar-light"
  >
    <CSidebarBrand 
      :imgFull="{ width: 148,height: 16, alt: 'Logo', src: 'img/logo_ocw.svg'}"
      :imgMinimized="{ width: 148, height: 150, alt: 'Logo', src: 'img/brand/coreui-signet-white.svg'}"
      :wrappedInLink="{ href: '/'}"
    />
    <!-- <CSidebarHeader/> -->
    <!-- <CSidebarForm/> -->
    <CRenderFunction flat :contentToRender="nav"/>
    <!-- <CSidebarFooter/> -->
    <button @click="signOut" type="button" class="btn btn-info" style="margin:2rem 1rem;">ログアウト</button>
    <CSidebarMinimizer 
      class="c-d-md-down-none" 
      @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
import firebase from '@firebase/app';
import nav from './_nav'
import {UserRole} from '@/common/const';

export default {
  name: 'TheSidebar',
  data () {
    return {
      minimize: false,
      nav,
      show: 'responsive',
      tmpNav: [],
    }
  },
  mounted () {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })

    this.tmpNav = JSON.parse(JSON.stringify(this.nav))
    this.nav[0]._children = []
    this.loadMenu()
  },



  methods: {
    signOut: function () {
      firebase.auth().signOut();
    },
    async loadMenu() {
      try {
        const firebaseUser = firebase.auth().currentUser
        // 未ログイン時はなにもしない
        if (firebaseUser == null) {
          return
        }

        const userResult = await firebase.firestore().collection('users').doc(firebaseUser.uid).get()
        const user = userResult.data()

        this.fetchMenu(user)

        // 小売だったら
        if (user.role == UserRole.DISTRIBUTIONS) {
          // 店舗情報取得
          let uid = user.id
          const userDoc = await firebase.firestore().collection('users').doc(uid).get()
          const parent_id = userDoc.data().parent_id
          if (parent_id) {
            uid = parent_id
          }
          const result = await firebase.firestore().collection('stores').where('created_user_id', '==', uid).get()
          const storeMenu = []
          result.forEach(function(doc) {
            const store = doc.data()
            storeMenu.push({
              storeCode: store.storeCode,
              name: store.name,
              to: '/stores/' + store.id + '/coupons',
              icon: 'cil-list'
            })
          })
          const menu = {
            _name: 'CSidebarNavDropdown',
            name: '店舗別クーポン管理',
            route: '/stores',
            icon: 'cil-notes',
            items: storeMenu.sort((a, b) => Number(a.storeCode) - Number(b.storeCode)),
          }
          this.setMenu(menu, 2)
        }
      } catch(e) {
        console.log(e)
      }
    },
    setMenu(menu, index) {
      const newChildren = []
      let cnt = 0
      this.tmpNav[0]._children.forEach(function(item) {
        if (cnt == index) {
          newChildren.push(menu)
        }
        newChildren.push(item)
        cnt++
      })
      this.tmpNav[0]._children = newChildren
      this.nav = this.tmpNav
    },
    fetchMenu(user) {
      // 権限チェック＆編集
      const newChildren = []
      this.tmpNav[0]._children.forEach(function(child) {
        if (child.user_roles === undefined) {
          return
        }
        const tmp = child.user_roles.find(role => role == user.role)
        if (tmp === undefined) {
          return
        }

        const newItems = []
        child.items.forEach(function(item) {
          if (item.user_roles === undefined) {
            return
          }
          const tmp = item.user_roles.find(role => role == user.role)
          if (tmp === undefined) {
            return
          }
          newItems.push(item)
        })

        child.items = newItems
        newChildren.push(child)
      })
      this.tmpNav[0]._children = newChildren
      this.nav = this.tmpNav
    },
  },
}
</script>

<style>
.c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-items {
  max-height: 30000px;
}
</style>