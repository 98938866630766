var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CDropdown",
    {
      staticClass: "c-header-nav-item mx-2",
      attrs: {
        placement: "bottom-end",
        caret: false,
        "in-nav": "",
        "add-menu-classes": "pt-0"
      },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function() {
            return [
              _c(
                "CHeaderNavLink",
                [
                  _c("CIcon", { attrs: { name: "cil-bell" } }),
                  _c("CBadge", { attrs: { pill: "", color: "danger" } }, [
                    _vm._v(_vm._s(_vm.itemsCount))
                  ])
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "CDropdownHeader",
        { staticClass: "text-center bg-light", attrs: { tag: "div" } },
        [
          _c("strong", [
            _vm._v("You have " + _vm._s(_vm.itemsCount) + " notifications")
          ])
        ]
      ),
      _c(
        "CDropdownItem",
        [
          _c("CIcon", {
            staticClass: "text-success",
            attrs: { name: "cil-user-follow" }
          }),
          _vm._v(" New user registered ")
        ],
        1
      ),
      _c(
        "CDropdownItem",
        [
          _c("CIcon", {
            staticClass: "text-danger",
            attrs: { name: "cil-user-unfollow" }
          }),
          _vm._v(" User deleted ")
        ],
        1
      ),
      _c(
        "CDropdownItem",
        [
          _c("CIcon", {
            staticClass: "text-info",
            attrs: { name: "cil-chart-pie" }
          }),
          _vm._v(" Sales report is ready ")
        ],
        1
      ),
      _c(
        "CDropdownItem",
        [
          _c("CIcon", {
            staticClass: "text-primary",
            attrs: { name: "cil-basket" }
          }),
          _vm._v(" New client ")
        ],
        1
      ),
      _c(
        "CDropdownItem",
        [
          _c("CIcon", {
            staticClass: "text-warning",
            attrs: { name: "cil-speedometer" }
          }),
          _vm._v(" Server overloaded ")
        ],
        1
      ),
      _c(
        "CDropdownHeader",
        { staticClass: "text-center bg-light", attrs: { tag: "div" } },
        [_c("strong", [_vm._v("Server")])]
      ),
      _c(
        "CDropdownItem",
        { staticClass: "d-block" },
        [
          _c("div", { staticClass: "text-uppercase mb-1" }, [
            _c("small", [_c("b", [_vm._v("CPU Usage")])])
          ]),
          _c("CProgress", {
            staticClass: "progress-xs",
            attrs: { color: "info", value: 25 }
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("348 Processes. 1/4 Cores.")
          ])
        ],
        1
      ),
      _c(
        "CDropdownItem",
        { staticClass: "d-block" },
        [
          _c("div", { staticClass: "text-uppercase mb-1" }, [
            _c("small", [_c("b", [_vm._v("Memory Usage")])])
          ]),
          _c("CProgress", {
            staticClass: "progress-xs",
            attrs: { color: "warning", value: 70 }
          }),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("11444GB/16384MB")
          ])
        ],
        1
      ),
      _c(
        "CDropdownItem",
        { staticClass: "d-block" },
        [
          _c("div", { staticClass: "text-uppercase mb-1" }, [
            _c("small", [_c("b", [_vm._v("SSD 1 Usage")])])
          ]),
          _c("CProgress", {
            staticClass: "progress-xs",
            attrs: { color: "danger", value: 90 }
          }),
          _c("small", { staticClass: "text-muted" }, [_vm._v("243GB/256GB")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }