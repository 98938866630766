var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CHeader",
    { attrs: { "with-subheader": "" } },
    [
      _c("CToggler", {
        directives: [
          {
            name: "c-emit-root-event",
            rawName: "v-c-emit-root-event:toggle-sidebar-mobile",
            arg: "toggle-sidebar-mobile"
          }
        ],
        staticClass: "ml-3 d-lg-none",
        attrs: { "in-header": "" }
      }),
      _c("CToggler", {
        directives: [
          {
            name: "c-emit-root-event",
            rawName: "v-c-emit-root-event:toggle-sidebar",
            arg: "toggle-sidebar"
          }
        ],
        staticClass: "ml-3 d-md-down-none",
        attrs: { "in-header": "" }
      }),
      _c("CHeaderBrand", {
        staticClass: "mx-auto d-lg-none",
        attrs: {
          src: "img/logo_oc.svg",
          width: "42",
          alt: "オープンクーポンロゴ"
        }
      }),
      _c(
        "CHeaderNav",
        { staticClass: "d-md-down-none mr-auto" },
        [_c("CHeaderNavItem", { staticClass: "px-3" })],
        1
      ),
      _c(
        "CHeaderNav",
        [
          _c("CHeaderNavItem", { staticClass: "px-3 align-right" }, [
            _vm._v(" " + _vm._s(_vm.getEmail()) + " ")
          ])
        ],
        1
      ),
      _c(
        "CSubheader",
        { staticClass: "px-3" },
        [_c("CBreadcrumbRouter", { staticClass: "border-0" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }