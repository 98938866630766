var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.show,
        colorScheme: "light",
        overlaid: "",
        fixed: false,
        size: "lg"
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function($event) {
            _vm.show = !_vm.show
          }
        }
      }),
      _c(
        "CTabs",
        {
          staticClass: "nav-underline nav-underline-primary",
          attrs: { tabs: "" }
        },
        [
          _c(
            "CTab",
            { attrs: { active: "" } },
            [
              _c(
                "template",
                { slot: "title" },
                [_c("CIcon", { attrs: { name: "cil-list" } })],
                1
              ),
              _c(
                "CListGroup",
                { staticClass: "list-group-accent" },
                [
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-secondary bg-light text-center \n          font-weight-bold text-muted text-uppercase small"
                    },
                    [_vm._v(" Today ")]
                  ),
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-warning list-group-item-divider",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", { staticClass: "c-avatar float-right" }, [
                        _c("img", {
                          staticClass: "c-avatar-img",
                          attrs: {
                            src: "img/avatars/7.jpg",
                            alt: "admin@bootstrapmaster.com"
                          }
                        })
                      ]),
                      _c("div", [
                        _vm._v("Meeting with "),
                        _c("strong", [_vm._v("Lucas")])
                      ]),
                      _c(
                        "small",
                        { staticClass: "text-muted mr-3" },
                        [
                          _c("CIcon", { attrs: { name: "cil-calendar" } }),
                          _vm._v(" 1 - 3pm ")
                        ],
                        1
                      ),
                      _c(
                        "small",
                        { staticClass: "text-muted" },
                        [
                          _c("CIcon", { attrs: { name: "cil-location-pin" } }),
                          _vm._v(" Palo Alto, CA ")
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "CListGroupItem",
                    {
                      staticClass: "list-group-item-accent-info",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", { staticClass: "c-avatar float-right" }, [
                        _c("img", {
                          staticClass: "c-avatar-img",
                          attrs: {
                            src: "img/avatars/4.jpg",
                            alt: "admin@bootstrapmaster.com"
                          }
                        })
                      ]),
                      _c("div", [
                        _vm._v("Skype with "),
                        _c("strong", [_vm._v("Megan")])
                      ]),
                      _c(
                        "small",
                        { staticClass: "text-muted mr-3" },
                        [
                          _c("CIcon", { attrs: { name: "cil-calendar" } }),
                          _vm._v(" 4 - 5pm ")
                        ],
                        1
                      ),
                      _c(
                        "small",
                        { staticClass: "text-muted" },
                        [
                          _c("CIcon", { attrs: { name: "cil-skype" } }),
                          _vm._v(" On-line ")
                        ],
                        1
                      )
                    ]
                  ),
                  _c("hr", { staticClass: "transparent mx-3 my-0" }),
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-secondary bg-light text-center \n          font-weight-bold text-muted text-uppercase small"
                    },
                    [_vm._v(" Tomorrow ")]
                  ),
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-danger list-group-item-divider",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", [
                        _vm._v("New UI Project - "),
                        _c("strong", [_vm._v("deadline")])
                      ]),
                      _c(
                        "small",
                        { staticClass: "text-muted mr-3" },
                        [
                          _c("CIcon", { attrs: { name: "cil-calendar" } }),
                          _vm._v(" 10 - 11pm ")
                        ],
                        1
                      ),
                      _c(
                        "small",
                        { staticClass: "text-muted" },
                        [
                          _c("CIcon", { attrs: { name: "cil-home" } }),
                          _vm._v(" creativeLabs HQ ")
                        ],
                        1
                      ),
                      _c("div", { staticClass: "c-avatars-stack mt-2" }, [
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/2.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/3.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/4.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/5.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/6.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-success list-group-item-divider",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", [
                        _c("strong", [_vm._v("#10 Startups.Garden")]),
                        _vm._v(" Meetup")
                      ]),
                      _c(
                        "small",
                        { staticClass: "text-muted mr-3" },
                        [
                          _c("CIcon", { attrs: { name: "cil-calendar" } }),
                          _vm._v(" 1 - 3pm ")
                        ],
                        1
                      ),
                      _c(
                        "small",
                        { staticClass: "text-muted" },
                        [
                          _c("CIcon", { attrs: { name: "cil-location-pin" } }),
                          _vm._v(" Palo Alto, CA ")
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-primary list-group-item-divider",
                      attrs: { href: "#" }
                    },
                    [
                      _c("div", [_c("strong", [_vm._v("Team meeting")])]),
                      _c(
                        "small",
                        { staticClass: "text-muted mr-3" },
                        [
                          _c("CIcon", { attrs: { name: "cil-calendar" } }),
                          _vm._v(" 4 - 6pm ")
                        ],
                        1
                      ),
                      _c(
                        "small",
                        { staticClass: "text-muted" },
                        [
                          _c("CIcon", { attrs: { name: "cil-home" } }),
                          _vm._v(" creativeLabs HQ ")
                        ],
                        1
                      ),
                      _c("div", { staticClass: "c-avatars-stack mt-2" }, [
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/2.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/3.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/4.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/5.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/6.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/7.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ]),
                        _c("div", { staticClass: "c-avatar c-avatar-xs" }, [
                          _c("img", {
                            staticClass: "c-avatar-img",
                            attrs: {
                              src: "img/avatars/8.jpg",
                              alt: "admin@bootstrapmaster.com"
                            }
                          })
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "CTab",
            [
              _c(
                "template",
                { slot: "title" },
                [_c("CIcon", { attrs: { name: "cil-speech" } })],
                1
              ),
              _c("div", { staticClass: "p-3" }, [
                _c("div", { staticClass: "message" }, [
                  _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                    _c("div", { staticClass: "c-avatar" }, [
                      _c("img", {
                        staticClass: "c-avatar-img",
                        attrs: {
                          src: "img/avatars/7.jpg",
                          alt: "admin@bootstrapmaster.com"
                        }
                      }),
                      _c("span", { staticClass: "bg-success c-avatar-status" })
                    ])
                  ]),
                  _c("div", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Lukasz Holeczek")
                    ]),
                    _c(
                      "small",
                      { staticClass: "text-muted float-right mt-1" },
                      [_vm._v("1:52 PM")]
                    )
                  ]),
                  _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                    _vm._v(" Lorem ipsum dolor sit amet ")
                  ]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.lorem))
                  ])
                ]),
                _c("hr"),
                _c("div", { staticClass: "message" }, [
                  _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                    _c("div", { staticClass: "c-avatar" }, [
                      _c("img", {
                        staticClass: "c-avatar-img",
                        attrs: {
                          src: "img/avatars/7.jpg",
                          alt: "admin@bootstrapmaster.com"
                        }
                      }),
                      _c("span", { staticClass: "bg-success c-avatar-status" })
                    ])
                  ]),
                  _c("div", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Lukasz Holeczek")
                    ]),
                    _c(
                      "small",
                      { staticClass: "text-muted float-right mt-1" },
                      [_vm._v("1:52 PM")]
                    )
                  ]),
                  _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                    _vm._v(" Lorem ipsum dolor sit amet ")
                  ]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.lorem))
                  ])
                ]),
                _c("hr"),
                _c("div", { staticClass: "message" }, [
                  _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                    _c("div", { staticClass: "c-avatar" }, [
                      _c("img", {
                        staticClass: "c-avatar-img",
                        attrs: {
                          src: "img/avatars/7.jpg",
                          alt: "admin@bootstrapmaster.com"
                        }
                      }),
                      _c("span", { staticClass: "bg-success c-avatar-status" })
                    ])
                  ]),
                  _c("div", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Lukasz Holeczek")
                    ]),
                    _c(
                      "small",
                      { staticClass: "text-muted float-right mt-1" },
                      [_vm._v("1:52 PM")]
                    )
                  ]),
                  _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                    _vm._v(" Lorem ipsum dolor sit amet ")
                  ]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.lorem))
                  ])
                ]),
                _c("hr"),
                _c("div", { staticClass: "message" }, [
                  _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                    _c("div", { staticClass: "c-avatar" }, [
                      _c("img", {
                        staticClass: "c-avatar-img",
                        attrs: {
                          src: "img/avatars/7.jpg",
                          alt: "admin@bootstrapmaster.com"
                        }
                      }),
                      _c("span", { staticClass: "bg-success c-avatar-status" })
                    ])
                  ]),
                  _c("div", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Lukasz Holeczek")
                    ]),
                    _c(
                      "small",
                      { staticClass: "text-muted float-right mt-1" },
                      [_vm._v("1:52 PM")]
                    )
                  ]),
                  _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                    _vm._v(" Lorem ipsum dolor sit amet ")
                  ]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.lorem))
                  ])
                ]),
                _c("hr"),
                _c("div", { staticClass: "message" }, [
                  _c("div", { staticClass: "py-3 pb-5 mr-3 float-left" }, [
                    _c("div", { staticClass: "c-avatar" }, [
                      _c("img", {
                        staticClass: "c-avatar-img",
                        attrs: {
                          src: "img/avatars/7.jpg",
                          alt: "admin@bootstrapmaster.com"
                        }
                      }),
                      _c("span", { staticClass: "bg-success c-avatar-status" })
                    ])
                  ]),
                  _c("div", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Lukasz Holeczek")
                    ]),
                    _c(
                      "small",
                      { staticClass: "text-muted float-right mt-1" },
                      [_vm._v("1:52 PM")]
                    )
                  ]),
                  _c("div", { staticClass: "text-truncate font-weight-bold" }, [
                    _vm._v(" Lorem ipsum dolor sit amet ")
                  ]),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.lorem))
                  ])
                ])
              ])
            ],
            2
          ),
          _c(
            "CTab",
            [
              _c(
                "template",
                { slot: "title" },
                [_c("CIcon", { attrs: { name: "cil-settings" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "p-3" },
                [
                  _c("h6", [_vm._v("Settings")]),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "clearfix mt-4" },
                      [
                        _c("small", [_c("b", [_vm._v("Option 1")])]),
                        _c("CSwitch", {
                          staticClass: "float-right",
                          attrs: {
                            color: "success",
                            labelOn: "On",
                            labelOff: "Off",
                            shape: "pill",
                            size: "sm",
                            checked: ""
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", [_c("small", { staticClass: "text-muted" })])
                  ]),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "clearfix mt-3" },
                      [
                        _c("small", [_c("b", [_vm._v("Option 2")])]),
                        _c("CSwitch", {
                          staticClass: "float-right",
                          attrs: {
                            color: "success",
                            labelOn: "On",
                            labelOff: "Off",
                            shape: "pill",
                            size: "sm"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.lorem))
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "clearfix mt-3" },
                      [
                        _c("small", [_c("b", [_vm._v("Option 3")])]),
                        _c("CSwitch", {
                          staticClass: "float-right",
                          attrs: {
                            color: "success",
                            labelOn: "On",
                            labelOff: "Off",
                            shape: "pill",
                            size: "sm",
                            disabled: "",
                            checked: ""
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("Disabled option.")
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "clearfix mt-3" },
                      [
                        _c("small", [_c("b", [_vm._v("Option 4")])]),
                        _c("CSwitch", {
                          staticClass: "float-right",
                          attrs: {
                            color: "success",
                            labelOn: "On",
                            labelOff: "Off",
                            shape: "pill",
                            size: "sm",
                            checked: ""
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("hr"),
                  _c("h6", [_vm._v("System Utilization")]),
                  _c("div", { staticClass: "text-uppercase mb-1 mt-4" }, [
                    _c("small", [_c("b", [_vm._v("CPU Usage")])])
                  ]),
                  _c("CProgress", {
                    staticClass: "progress-xs",
                    attrs: { color: "info", value: 25 }
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("348 Processes. 1/4 Cores.")
                  ]),
                  _c("div", { staticClass: "text-uppercase mb-1 mt-2" }, [
                    _c("small", [_c("b", [_vm._v("Memory Usage")])])
                  ]),
                  _c("CProgress", {
                    staticClass: "progress-xs",
                    attrs: { color: "warning", value: 70 }
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("11444GB/16384MB")
                  ]),
                  _c("div", { staticClass: "text-uppercase mb-1 mt-2" }, [
                    _c("small", [_c("b", [_vm._v("SSD 1 Usage")])])
                  ]),
                  _c("CProgress", {
                    staticClass: "progress-xs",
                    attrs: { color: "danger", value: 95 }
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("243GB/256GB")
                  ]),
                  _c("div", { staticClass: "text-uppercase mb-1 mt-2" }, [
                    _c("small", [_c("b", [_vm._v("SSD 2 Usage")])])
                  ]),
                  _c("CProgress", {
                    staticClass: "progress-xs",
                    attrs: { color: "success", value: 10 }
                  }),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("25GB/256GB")
                  ])
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }