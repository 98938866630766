<template>
  <div class="c-app">
    <TheSidebar/>
    <div class="c-wrapper">
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </div>
<!--    <TheFooter/>-->
    <div v-show="isLoading">
      <div id="gray">
        <div id="loading-sp" class="sk-wave">
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
          <div class="sk-wave-rect"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheAside from './TheAside'
import { mapState } from 'vuex'

export default {
  name: 'TheContainer',
  computed: mapState({
    isLoading: state => state.isLoading,
  }),
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside
  }
}
</script>

<style src="spinkit/spinkit.min.css"></style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
  #gray {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: gray;
    opacity: 0.5;
    z-index: 2000;
  }
  #loading-sp {
    position: fixed;
    top: 50%;
    right: 50%;
    width: 3rem;
    height: 3rem;
  }
</style>
