<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
      class="mx-auto d-lg-none" 
      src="img/logo_oc.svg"
      width="42"
      alt="オープンクーポンロゴ"
    />
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <!-- <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink> -->
      </CHeaderNavItem>
 <!--      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem> -->
    </CHeaderNav>
    <CHeaderNav>
      <CHeaderNavItem class="px-3 align-right">
        {{ getEmail() }}
      </CHeaderNavItem>
<!--       <TheHeaderDropdownNotif/>
      <TheHeaderDropdownTasks/>
      <TheHeaderDropdownMssgs/> -->
      <!-- <TheHeaderDropdownAccnt/> -->
      <!-- <CToggler
        in-header
        class="d-md-down-none"
        v-c-emit-root-event:toggle-aside
      >
        <CIcon size="lg" name="cil-applications-settings"/>
      </CToggler> -->
    </CHeaderNav>
 
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
import TheHeaderDropdownTasks from './TheHeaderDropdownTasks'
import TheHeaderDropdownMssgs from './TheHeaderDropdownMssgs'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownNotif,
    TheHeaderDropdownTasks,
    TheHeaderDropdownMssgs
  },
  data () {
    return {
      sidebarIsMinimized: false,
    }
  },
  methods: {
    getEmail () {
      return localStorage.getItem('email')
    }
  },
  computed: {
    logoClasses () {
      return [
        'c-header-brand',
        { 'c-header-brand-minimized': this.sidebarIsMinimized }
      ]
    }
  },
  mounted () {
    this.$root.$on('c-sidebar-minimize-state', (state) => {
      this.sidebarIsMinimized = state
    })
  }
}
</script>
